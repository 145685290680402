
/*Tabs single product*/

jQuery(function(){

  jQuery(".woocommerce-tabs .panel.entry-content").hide();

  jQuery(".woocommerce-tabs ul.tabs li").first().addClass("active");

  jQuery(".woocommerce-tabs .panel.entry-content").first().show();

  

  jQuery(".woocommerce-tabs ul.tabs li a").click(function(){

    $v = jQuery(this).attr("rel");

    jQuery(".woocommerce-tabs .panel.entry-content").hide();

    

    jQuery(".woocommerce-tabs ul.tabs li").removeClass("active");

    jQuery(this).parent().addClass("active");

  

    jQuery(".woocommerce-tabs #"+$v).show();

    return false;

  });

})