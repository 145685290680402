jQuery(document).ready(function($) {
    $('#animation').addClass('animate');

    $('.heart img').popover({
        placement: 'top',
        html: true,
        container: '#animation',
        content: function () {
            return $(this).attr('alt');
        }
    });

    $('body').on('click', function(e) {
        $('.heart img').each(function() {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.heart img').has(e.target).length === 0) {
                $(this).popover('hide');
            } else {
                $(this).popover('toggle');
            }
        });
    });

    // var address;
    // var geocoder;

    function render_map($el) {
        //Get the address
        // address = document.getElementById('address').getAttribute('data-address');
        // console.log(address);
        // //initiate gecoder
        // geocoder = new google.maps.Geocoder();
        // console.log(geocoder);

        // $geocoder.geocode( { 'address': $address}, function($results, $status) {
        //   if ($status == google.maps.GeocoderStatus.OK) {
        //        console.log(results[0].geometry.location);
        //   } else {
        //     alert("Geocode was not successful for the following reason: " + $status);
        //   }
        // });

        var $markers = $(document).find('#locations .data-source li');

        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP]
            }
        };

        var map = new google.maps.Map($el[0], args);

        map.markers = [];

        index = 0;
        $markers.each(function() {
            add_marker($(this), map, index);
            index++;
        });

        center_map(map);
    }

    function add_marker($marker, map, index) {
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
        var image = '../../img/maps-leaf.png';
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: image
        });
        map.markers.push(marker);
        if ($marker.html()) {
            $('#locations .data-display').append('<li class="linkage" id="p'+index+'">'+$marker.html()+'</li>');

            $(document).on('click', '#p' + index, function() {
                infowindow.open(map, marker);
                setTimeout(function() { infowindow.close(); }, 5000);
            });

            var infowindow = new google.maps.InfoWindow({
                content: $marker.html(),
            });

            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    }

    function center_map(map) {
        var bounds = new google.maps.LatLngBounds();
        $.each( map.markers, function( i, marker ){
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
        });

        if( map.markers.length == 1 ) {
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        } else {
            map.fitBounds( bounds );
        }
    }

    $(document).ready(function(){
        $('#map').each(function(){
            render_map( $(this) );
        });
    });
});
