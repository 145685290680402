// JavaScript Document
/* <![CDATA[ */
//Slider for home page 1
if(jQuery("#tn-slider1_56126ca14c65f").length){
	var tn_sliders_data = {"tn-slider1_56126ca14c65f":{"slider_id":"tn-slider1_56126ca14c65f","type":"big_center_carousel"}};
}
//Slider for media-post-formats-classic-post
if(jQuery("#tn-slider1_562dec8d3d8c9").length){
	var tn_sliders_data = {"tn-slider1_562dec8d3d8c9":{"slider_id":"tn-slider1_562dec8d3d8c9","type":"single_slider"}};
}

//Slider for category-layout-01
if(jQuery("#tn-slider2_562af39740a36").length){
	var tn_sliders_data = {"tn-slider2_562af39740a36":{"slider_id":"tn-slider2_562af39740a36","type":"small_center_carousel"}};
}
//var tn_sliders_data = {"tn-slider2_562af39740a36":{"slider_id":"tn-slider2_562af39740a36","type":"small_center_carousel"}};

if(jQuery("#tn-slider1_5619e57d1170b").length){
	//Slider for home page red
	var tn_sliders_data = {"tn-slider1_5619e57d1170b":{"slider_id":"tn-slider1_5619e57d1170b","type":"small_center_carousel"}};
}

if(jQuery("#tn-block-image-parallax").length){
	var tn_block_parallax_image = jQuery("#tn-block-image-parallax").attr("data-background-image");
}else{
	var tn_block_parallax_image = "";
}
if(jQuery("#tn-slider-single").length){
	var tn_sliders_data = {"tn-slider-single":{"slider_id":"tn-slider-single","type":"single_slider"}};
}




if(jQuery("#tn-banner-image-parallax").length){
	var tn_banner_parallax_image = jQuery("#tn-banner-image-parallax").attr("data-background-image-header");
}else{
	var tn_banner_parallax_image = "";
}

if(jQuery("#tn-single-image-parallax").length){
	var tn_single_parallax_image = jQuery("#tn-single-image-parallax").attr("data-background-image-header");
}else{
	var tn_single_parallax_image = "";
}

if(jQuery("#tn-single-image-parallax").length){
	var tn_single_parallax_image = jQuery("#tn-single-image-parallax").attr("data-background-image-header");
}else{
	var tn_single_parallax_image = "";
}

//var tn_block_parallax_image = "images\/de9uL9L7RSmzV4SAoAO5_Lauren-and-Winona-Under-a-pass-1.jpg";
var tn_sticky_navigation = "1";
var tn_sidebar_sticky_enable = "1";
var tn_to_top = "1";
var tn_to_top_mobile = "0";
var tn_site_smooth_scroll = "1";
/* ]]> */



 (function ($) {
                $(function () {
                    var $switch = $('.style-selector .switch');

                    var toggle_switcher = function (status) {
                        if (status == 'open') {
                            // open
                            $('.style-selector').css('left', '0');
                            $switch.attr('status', 'open');
                        } else {
                            // close
                            $('.style-selector').css('left', '-181px');
                            $switch.attr('status', 'closed');
                        }
                    };
                    $switch.click(function () {
                        if ($switch.attr('status') == 'closed') {
                            toggle_switcher('open');
                        } else {
                            toggle_switcher('closed');
                        }
                    });



                    $('.color-small-box').click(function () {
                        if ($(this).hasClass('selected')) return;

                        $(this).siblings().removeClass('selected');
                        $(this).toggleClass('selected');
                    });


                });
            })(jQuery);
			
			//ajax search
			jQuery('#tn-ajax-form-search').click(function (e) {
				e.preventDefault();
				jQuery(this).toggleClass('ajax-search-hover');
				jQuery(this).next().slideToggle(250);
			});