jQuery(document).ready(function() {
    jQuery('.tn-login-content-wrap .tn-login-inner input[type=text].form-text.required').attr('placeholder','Username');
    jQuery('.tn-login-content-wrap .tn-login-inner form input[type=password]').attr('placeholder','Password');
    jQuery('#tn-forgot-pass-inner .block-forgot-password-block form input[type=text]').attr('placeholder','Enter E-mail');
    jQuery('#tn-forgot-pass-inner .block-forgot-password-block form input[type=text]').after('<i class="fa fa-envelope-o"></i>');
    jQuery('.tn-login-content-wrap .tn-login-inner input[type=text].form-text.required').after('<i class="fa fa-user"></i>');
    jQuery('.tn-login-content-wrap .tn-login-inner form input[type=password]').after('<i class="fa fa-lock"></i>');
    jQuery('.main-menu-wrap > ul.tn-sub-menu').attr('class','main-menu');
    jQuery('.main-menu-wrap > ul > li.tn-has-mega-menu > ul.tn-sub-menu').wrap('<div class="tn-sub-menu-wrap">');
    jQuery('.main-menu-wrap > ul > li.tn-has-mega-menu > .tn-sub-menu-wrap').wrap('<div class="tn-mega-menu-col clearfix">');
    jQuery('.main-menu-wrap > ul > li.dropdown-menu-item > ul.tn-sub-menu').wrap('<div class="tn-sub-menu-wrap">');
    jQuery('.main-menu-wrap > ul > li.dropdown-menu-item > .tn-sub-menu-wrap').wrap('<div class="tn-dropdown-menu">');
    jQuery('.zmagazine-shop-catalog .commerce-add-to-cart input[type=submit]').attr('style','display:block');
    jQuery('.zmagazine-shop-catalog .commerce-add-to-cart input[type=submit]').attr('class','button add_to_cart_button');						
    jQuery('.zmagazine-shop-catalog:nth-child(3n+0)').addClass('last');
    jQuery('.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget input[type=submit]').val('×');
    jQuery('.tn-sticky-sidebar .woocommerce.widget_shopping_cart .product_list_widget input[type=submit]').attr('style','display:block');
    jQuery('.tn-main-menu-wrap .main-menu-wrap').removeClass('hidden-menu');
    jQuery('#bb9b223f-57de-4669-9291-7c97592b8b98 > .tn-block3-content-wrap > .tn-block3-content-inner > .tn-block3-element').each(function() {
        jQuery('#bb9b223f-57de-4669-9291-7c97592b8b98 > .tn-block3-content-wrap > .tn-block3-content-inner > .tn-block3-element').slice(0,2).wrapAll('<div class="row clearfix"></div>');
    });
});
